import { Typography } from '@mui/material';
import { usePageTitle } from '../hooks';

export function Home() {
  usePageTitle('BillBergquist.com');

  return <Typography variant="body2">Welcome to my home page.</Typography>;
}

export default Home;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './routes/home';
import { Layout } from './components/layout/layout';
import Error from './routes/error';
import Memory from './routes/memory/memory';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/memory" element={<Memory />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Layout>
  );
}

export default App;

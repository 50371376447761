import { usePageTitle } from '../../hooks';
import { Grid2 as Grid, Stack } from '@mui/material';
import MemoryCard from './memoryCard';
import { useEffect, useMemo, useState } from 'react';
import Scoreboard from './scoreboard';

const LOCAL_STORAGE_KEY_SALT = 'memory-game-high-score';
const HARD_FADE = 333;
const MEDIUM_FADE = 666;
const EASY_FADE = 999;

export const ALL_IMAGES = [
  'marge1',
  'marge1',
  'marge2',
  'marge2',
  'marge3',
  'marge3',
  'pico1',
  'pico1',
  'pico2',
  'pico2',
  'pico3',
  'pico3',
];

export type Card = {
  image: string;
  flipped: boolean;
  scored: boolean;
};

export type Difficulty = 'easy' | 'medium' | 'hard';

function buildLocationStorageKey(difficulty: Difficulty) {
  return `${LOCAL_STORAGE_KEY_SALT}-${difficulty}`;
}

export function Memory() {
  const [cards, setCards] = useState<Card[]>([]);
  const [flipLock, setFlipLock] = useState(false);
  const [guesses, setGuesses] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [isNewHighScore, setIsNewHighScore] = useState(false);
  const [difficulty, setDifficulty] = useState<Difficulty>('easy');
  const [fadeTime, setFadeTime] = useState<number>(EASY_FADE);

  const hasWon = cards.length ? cards.every((card) => card.scored) : false;
  const localStorageKey = useMemo(() => buildLocationStorageKey(difficulty), [difficulty]);

  const resetCards = () => {
    const shuffled = ALL_IMAGES.sort(() => Math.random() - 0.5);
    setCards(shuffled.map((image) => ({ image, scored: false, flipped: false })));
    setFlipLock(false);
    setGameOver(false);
    setGuesses(0);
    setIsNewHighScore(false);
  };

  const scoreCards = (index1: number, index2: number) => {
    const newCards = [...cards];
    newCards[index1].scored = true;
    newCards[index2].scored = true;
    setCards(newCards);
    setFlipLock(false);
  };

  const unflipAll = () => {
    const newCards = cards.map((card) => ({ ...card, flipped: false }));
    setCards(newCards);
    setFlipLock(false);
  };

  const flipCard = (index: number) => {
    if (flipLock || cards[index].flipped || cards[index].scored) {
      return;
    }

    const newCards = [...cards];
    newCards[index].flipped = !newCards[index].flipped;
    setCards(newCards);

    const flippedCards = newCards.filter((card) => card.flipped && !card.scored);

    if (flippedCards.length === 2) {
      setFlipLock(true);
      setGuesses((prev) => prev + 1);

      if (flippedCards[0].image === flippedCards[1].image) {
        scoreCards(newCards.indexOf(flippedCards[0]), newCards.indexOf(flippedCards[1]));
      } else {
        setTimeout(unflipAll, fadeTime);
      }
    }
  };

  const recordScore = () => {
    const highScore = localStorage.getItem(localStorageKey);
    if (!highScore || guesses < parseInt(highScore, 10)) {
      localStorage.setItem(localStorageKey, guesses.toString());
      setIsNewHighScore(true);
    }
  };

  const changeDifficulty = (difficulty: Difficulty) => {
    setDifficulty(difficulty);
    resetCards();
    switch (difficulty) {
      case 'easy':
        setFadeTime(EASY_FADE);
        break;
      case 'medium':
        setFadeTime(MEDIUM_FADE);
        break;
      case 'hard':
        setFadeTime(HARD_FADE);
        break;
    }
  };

  useEffect(() => {
    resetCards();
  }, []);

  useEffect(() => {
    if (hasWon) {
      recordScore();
      setGameOver(true);
    }
  }, [hasWon, recordScore]);

  usePageTitle('BillBergquist.com | Memory game');

  return (
    <Stack spacing={2}>
      <Scoreboard
        cards={cards}
        clicks={guesses}
        resetCards={resetCards}
        difficulty={difficulty}
        changeDifficulty={changeDifficulty}
        gameOver={gameOver}
        isNewHighScore={isNewHighScore}
        localStorageKey={localStorageKey}
      />
      <Grid container spacing={2}>
        {cards.map((card, index) => (
          <MemoryCard
            key={index}
            image={card.image}
            onClick={() => flipCard(index)}
            flipped={card.flipped}
            scored={card.scored}
          />
        ))}
      </Grid>
    </Stack>
  );
}

export default Memory;

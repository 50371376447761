import { Box, Grid2 as Grid } from '@mui/material';

type CardProps = {
  image: string;
  flipped: boolean;
  scored: boolean;
  onClick: () => void;
};

export function MemoryCard({ image, flipped, scored, onClick }: CardProps) {
  const imgUrl = `/images/${image}.png`;
  const isFlipped = flipped || scored;

  const handleFlip = () => {
    onClick();
  };

  return (
    <Grid
      size={{ xs: 4, md: 3 }}
      height={{ xs: 132, md: 343 }}
      display="flex"
      justifyContent="center"
    >
      {!isFlipped ? (
        <Box
          sx={{
            boxSizing: 'border-box',
            background:
              'linear-gradient(142deg, rgba(255,0,0,1) 0%, rgba(255,141,141,1) 50%, rgba(255,0,0,1) 100%)',
            borderRadius: 5,
            borderColor: 'black',
            border: 5,
          }}
          onClick={handleFlip}
          width={{ xs: 100, md: 260 }}
          height={{ xs: 132, md: 343 }}
        />
      ) : (
        <Box
          component="img"
          src={imgUrl}
          alt={image}
          sx={{
            boxSizing: 'border-box',
            backgroundColor: 'black',
            borderRadius: 5,
            borderColor: 'red',
            border: 5,
          }}
          onClick={handleFlip}
          width={{ xs: 100, md: 260 }}
          height={{ xs: 132, md: 343 }}
        />
      )}
    </Grid>
  );
}

export default MemoryCard;

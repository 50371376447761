import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Card, Difficulty } from './memory';

type ScoreboardProps = {
  cards: Card[];
  clicks: number;
  gameOver: boolean;
  isNewHighScore: boolean;
  resetCards: () => void;
  difficulty: Difficulty;
  changeDifficulty: (difficulty: Difficulty) => void;
  localStorageKey: string;
};

export function Scoreboard({
  cards,
  clicks,
  resetCards,
  gameOver,
  isNewHighScore,
  changeDifficulty,
  difficulty,
  localStorageKey,
}: ScoreboardProps) {
  const remaining = cards.filter((card) => !card.scored).length / 2;
  const highScore = localStorage.getItem(localStorageKey);

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      height={40}
    >
      {gameOver ? (
        <Typography variant="h6" sx={{ color: 'green' }}>
          You won{isNewHighScore ? ', with a new high score' : ''}! | Guesses: {clicks} | High score
          ({difficulty}): {highScore}
        </Typography>
      ) : (
        <Typography variant="h6">
          Guesses: {clicks} | Remaining: {remaining}
        </Typography>
      )}

      {gameOver ? (
        <Button variant="contained" onClick={resetCards}>
          Play again
        </Button>
      ) : (
        <FormControl>
          <InputLabel id="difficulty-select">Difficulty</InputLabel>
          <Select
            size="small"
            id="difficulty-select"
            label="Difficulty"
            value={difficulty}
            onChange={(e) => changeDifficulty(e.target.value as Difficulty)}
          >
            <MenuItem value="easy">Easy</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="hard">Hard</MenuItem>
          </Select>
        </FormControl>
      )}
    </Stack>
  );
}

export default Scoreboard;
